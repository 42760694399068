import React from 'react'

import { Jumbotron, Container, Row, Col } from 'react-bootstrap';

import Image from '../image';

export default (props) => {
    return (
        <>
            <style type="text/css">
            {`
                .jumbotron-hero {
                    min-height: 50vh;
                    width: 100%;
                    position: relative;
                    color: #fff;
                    margin: 0 auto;
                    margin-bottom: 0;
                    overflow: hidden;
                }
                .jumbotron-hero .container {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    z-index: 3;
                }
                .jumbotron-title {
                    font-size: 40px;
                    font-weight: bold;
                    text-shadow: 0px 1px #000;
                    line-height: 1.1;
                    letter-spacing: 1px;
                }
                .dark-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.5);
                    z-index: 2;
                }
                .hero-img {
                    position: absolute;
                    top: -60%;
                    height: 100%;
                    z-index: 1;
                }
                .hero-img img {
                    width: 100%;
                }
                @media(min-width: 768px) {
                    .hero-img {
                        left: 0;
                        width: 100%;
                    }
                    .jumbotron-hero .container {
                        bottom: 5%;
                    }
                }
                @media(max-width: 767px) {
                    .jumbotron-hero {
                        width: 100%;
                    }
                    .hero-img {
                        left: -70%;
                        width: 300%;
                    }
                    .jumbotron-hero .container {
                        bottom: 5%;
                    }
                    .jumbotron-title {
                        font-size: 30px;
                    }
                }
            `}
            </style>
            <Jumbotron className="jumbotron-hero">
                <div className="dark-bg"></div>
                <div className="hero-img">
                    <Image src={props.img} alt={props.title} />
                </div>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="jumbotron-title">{props.title}</h1>
                            <p>{props.desc}</p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </>
    )
}
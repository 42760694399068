import React from 'react'

import SEO from '../components/seo'
import { Container, Row, Col, Media } from 'react-bootstrap';
import Layout from '../components/layout'

import { Map, Telephone, Envelope, Stopwatch, ShareFill, Facebook } from 'react-bootstrap-icons'
import HeroPage from '../components/views/hero-page'

export default function Contact() {
  return (
    <Layout>
      <style>{`
        .contact-icon-size {
          font-size: 40px;
          color: #07260E !important;
        }
        .media-body h5 {
          font-weight: bold;
        }
        .w-hours {
          width: 90px;
          display: inline-block;
        }
      `}</style>
      <SEO title="Contact us" img={`/img/slider4.jpg`} />
        <HeroPage
          title="Contact us"
          desc="Welcome to Sumo Japanese Restaurant! Easy Online Ordering, Dine In, Take Out or Delivery!"
          img="slider4.jpg"
        />
        <div style={{marginTop: '50px', marginBottom: '50px'}}>
            <Container>
                <Row>
                    <Col md={4}>
                      <Media className="mb-4">
                        <Map className="contact-icon-size mr-4" />
                        <Media.Body>
                          <h5>ADDRESS</h5>
                          <p>
                            <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Sumo+Japanese+Restaurant/@37.8875454,-122.3000249,17z/data=!3m1!4b1!4m5!3m4!1s0x80857932f3b4ac9f:0xb617184f523f4474!8m2!3d37.8875454!4d-122.2978362">977 San Pablo Ave Albany, CA 94706</a>
                          </p>
                        </Media.Body>
                      </Media>
                      <Media className="mb-4">
                        <Telephone className="contact-icon-size mr-4" />
                        <Media.Body>
                          <h5>PHONE</h5>
                          <p>
                            <a href="tel:+1 (510) 524-4700">+1 (510) 524-4700</a>
                          </p>
                        </Media.Body>
                      </Media>
                      <Media className="mb-4">
                        <Envelope className="contact-icon-size mr-4" />
                        <Media.Body>
                          <h5>E-MAIL</h5>
                          <p>
                            <a href="mailto:novasumosushi@gmail.com">novasumosushi@gmail.com</a>
                          </p>
                        </Media.Body>
                      </Media>
                      <Media className="mb-4">
                        <Stopwatch className="contact-icon-size mr-4" />
                        <Media.Body>
                          <h5>HOURS</h5>
                          <p>
                            <span className="mr-1 w-hours">Monday</span> Closed<br />
                            <span className="mr-1 w-hours">Tue - Fri:</span> 11:00 AM - 02:30 PM<br />
                            <span className="mr-1 w-hours"></span> 04:30 PM - 08:30 PM<br />
                            <span className="mr-1 w-hours">Saturday</span> 12:00 PM - 8:30 PM<br />
                            <span className="mr-1 w-hours">Sunday</span> 04:00 PM - 8:30 PM
                          </p>
                        </Media.Body>
                      </Media>
                      <Media className="mb-4">
                        <ShareFill className="contact-icon-size mr-4" />
                        <Media.Body>
                          <h5>SOCIAL</h5>
                          <p>
                            <a style={{marginRight: '15px'}} aria-label="Facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/Sumo-Sushi-438125709564928"><Facebook /></a>
                            <a style={{marginRight: '15px'}} aria-label="Yelp" target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/sumo-sushi-albany"><i>Y</i>elp</a>
                          </p>
                        </Media.Body>
                      </Media>
                    </Col>
                    <Col md={8}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.834822868037!2d-122.30002488421445!3d37.88754537973913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80857932f3b4ac9f%3A0xb617184f523f4474!2sSumo%20Japanese%20Restaurant!5e0!3m2!1sen!2sus!4v1616103300655!5m2!1sen!2sus" title="contact-map-2021" width="100%" height="620" style={{border: '0px'}} allowfullscreen="" loading="lazy"></iframe>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
  )
}
